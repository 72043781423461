@import "~@ui/styles/tools";

.section {
    position: relative;
}

.lights {
    pointer-events: none;
    position: absolute;
    width: 95%;
    height: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .rectangle {
        display: none;
    }

    @include media-tablet {
        width: 885px;

        .rectangle {
            display: block;
        }

        .square {
            display: none;
        }
    }

    @include media-mobile {
        width: 95%;

        .square {
            display: block;
        }

        .rectangle {
            display: none;
        }
    }
}
