@import "~@ui/styles/tools";

@mixin large-breakpoint {
    @media screen and (max-width: 1800px) {
        @content;
    }
}

@mixin medium-breakpoint {
    @media screen and (max-width: 1600px) {
        @content;
    }
}

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 100px;

    @include medium-breakpoint {
        gap: 50px;
    }

    @include media-tablet-portrait {
        gap: 56px;
    }
}

.duration {
    font-size: 36px;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0em;

    @include large-breakpoint {
        font-size: 26px;
        line-height: 37px;
    }

    @include medium-breakpoint {
        font-size: 36px;
        line-height: 47px;
    }

    @include media-tablet {
        font-size: 26px;
        line-height: 37px;
    }

    @include media-tablet-portrait {
        font-size: 24px;
        font-weight: 500;
        line-height: 31px;
    }
}

.prizes-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.prizes-amount {
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    color: #A2A8AD;

    @include large-breakpoint {
        font-size: 20px;
        line-height: 20px;
    }

    @include medium-breakpoint {
        font-size: 28px;
        line-height: 31px;
    }

    @include media-tablet {
        font-size: 20px;
        line-height: 20px;
    }

    @include media-tablet-portrait {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
    }
}

.ball {
    @include media-tablet-portrait {
        position: absolute;
        scale: 1.3;
        right: 16px;
        top: 50%;
        transform: translateY(-40%);
    }
}
