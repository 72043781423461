@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1600px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1400px) {
        @content;
    }
}

.container {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;

    @include medium-breakpoint {
        grid-template-columns: 1fr;
    }
}
