@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1450px) {
        @content;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.title {
    margin: 0;
    font-size: 14px;
    text-align: center;

    @include media-tablet-portrait {
        font-size: 12px;
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 18px;
    border: 1px solid #C1C1C1;
    background: #C1C1C11F;
    border-radius: 16px;

    @include media-tablet-portrait {
        padding: 16px;
    }
}

.timerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 8px;

    @include media-tablet-portrait {
        display: flex;
        justify-content: space-around;
        width: 100%;
        max-width: 300px;
    }
}

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5ch;
    height: 5ch;

    @include media-tablet {
        width: 4ch;
        height: 4ch;
    }
}

.number {
    color: #2B3A50;
    font-size: 28px;
    font-weight: 500;
    line-height: 1;

    @include media-tablet {
        font-size: 24px;
    }
    @include media-tablet-portrait {
        font-size: 20px;
    }
}

.subtitle {
    color: #2B3A50;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;

    @include media-tablet {
        font-size: 12px;
    }
}